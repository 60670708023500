@font-face {
  font-family: "DIN Medium";
  src: url(../assets/fonts/DIN-Medium.otf);
}

@font-face {
  font-family: "DIN Light";
  src: url(../assets/fonts/DIN-Light.otf);
}

@font-face {
  font-family: "DIN Pro Medium";
  src: url(../assets/fonts/DINPro-Medium.otf);
}

/* body {
  font-family: 'DIN Medium', sans-serif;
} */

:root {
  --app-background-color: hsl(240, 23%, 97%);
  --main-color: hsl(166, 29%, 54%);
  --main-color-darker: hsl(166, 29%, 44%);
  --main-color-lighter: hsl(166, 29%, 64%);
  --main-color-text: hsl(0, 0%, 100%);
  --main-color-text-darker: hsl(0, 0%, 90%);
  --secondary-color: hsl(15, 72%, 53%);
  --secondary-color-darker: hsl(15, 72%, 43%);
  --secondary-color-lighter: hsl(15, 72%, 63%);
  --secondary-color-text: hsl(0, 0%, 0%);
  --header-height: 4rem;
  --header-enlargement-height: 2rem;
  --header-background-color: hsl(0, 0%, 100%);
  --header-font-color: hsl(0, 0%, 0%);
  --error-color: hsl(354, 70%, 50%);
  --error-color-light: hsl(354, 70%, 60%);
  --info-color: hsl(218, 75%, 60%);
  --info-color-light: hsl(218, 75%, 70%);
  --grayish-color: hsl(0, 0%, 80%);
  --grayish-color-dark: hsl(0, 0%, 70%);
  --dialog-header-height: 4rem;
  --standard-border-radius: 3px;
}

html * {
  /* font-family: 'DIN Pro Medium', sans-serif !important; */
  /* font-family: 'DIN Light', sans-serif !important; */
  font-family: 'Barlow', sans-serif !important;
  /* font-size: 1.01em; */
}

.btn-primary-custom {
  border-color: var(--secondary-color);
  background-color: var(--secondary-color);
  color: var(--app-background-color);
}

.btn-primary-custom:hover,
.btn-primary-custom:active,
.btn-primary-custom:focus  {
  border-color: var(--secondary-color-darker);
  background-color: var(--secondary-color-darker);
}

.btn-primary-custom:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-color-lighter);
}

.btn-primary-custom:disabled {
  border-color: var(--secondary-color-darker);
  background-color: var(--secondary-color-darker);
  color: var(--main-color-text);
  opacity: 0.8;
}

/* .btn-primary-custom {
  border-color: var(--main-color);
  background-color: var(--main-color);
}

.btn-primary-custom:hover,
.btn-primary-custom:active,
.btn-primary-custom:focus  {
  border-color: var(--main-color);
  background-color: var(--main-color);
}

.btn-primary-custom:focus {
  box-shadow: 0 0 0 0.2rem var(--main-color-lighter);
}

.btn-primary-custom:disabled {
  border-color: var(--main-color-darker);
  background-color: var(--main-color-darker);
  color: var(--main-color-text);
  opacity: 0.8;
} */

.btn-secondary-custom {
  border-color: var(--secondary-color);
  background-color: var(--secondary-color);
  color: var(--main-color-text);
}

.btn-secondary-custom:hover,
.btn-secondary-custom:active,
.btn-secondary-custom:focus  {
  border-color: var(--secondary-color);
  background-color: var(--secondary-color);
  color: var(--main-color-text);
}

.btn-secondary-custom:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-color-lighter);
  color: var(--main-color-text);
}

.btn-secondary-custom:disabled {
  border-color: var(--secondary-color-darker);
  background-color: var(--secondary-color-darker);
  color: var(--main-color-text);
  opacity: 0.8;
}
/*  */
.btn-third-custom {
  border-color: var(--secondary-color);
  background-color: var(--app-background-color);
  color: var(--secondary-color);
}

.btn-third-custom:hover,
.btn-third-custom:active,
.btn-third-custom:focus  {
  border-color: var(--secondary-color);
  background-color: var(--secondary-color);
  color: var(--app-background-color);
}

.btn-third-custom:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-color-lighter);
  color: var(--secondary-color);
}

.btn-third-custom:disabled {
  border-color: var(--secondary-color-darker);
  background-color: var(--app-background-color);
  color: var(--secondary-color-darker);
  opacity: 0.8;
}
/*  */
.btn-textOnly {
  border: none;
  background-color: inherit;
  padding: 6px 6px;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
  color: var(--secondary-color);
}

.btn-textOnly:hover {
  text-decoration: underline;
}

body { 
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-dialog-container {
  padding: 0px !important;
}

.dialog-buttons-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.input-error {
  text-align: end;
  font-size: 0.8em;
  color: var(--error-color);
}

.application-view-main-container {
  padding: 0.8rem 0.5rem;
  width: 70rem;
  max-width: 100%;
  margin: auto;
}

.application-view-main-container-wider {
  width: 100rem;
}

.application-view-main-container-narrower {
  width: 50rem;
}

.application-view-title {
  text-align: start;
  font-weight: 500;
  font-size: 1.25em;
  margin-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-radius: var(--standard-border-radius);
  color: var(--main-color-text);
  background-color: var(--secondary-color);
}

.app-card-wrapper {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  border-radius: var(--standard-border-radius);
  border: 1px solid var(--secondary-color-lighter);
  background-color: var(--app-background-color);
}

.app-card-wrapper-hover-zoom-effect {
  transform: scale(1);
  transition: transform 0.15s ease-out;
}

.app-card-wrapper-hover-zoom-effect:hover {
  transform: scale(1.01);
  transform-origin: center center;
}

.app-card-image-and-data-container {
  width: 100%;
  display: flex;
  align-items: stretch;
  gap: 1rem;
  min-height: 10rem;
}

.app-card-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-card-image-container > img {
  max-width: 15rem;
  max-height: 15rem;
  background-color: white;
  border-radius: 1rem;
}

.app-card-data-container {
  flex-grow: 1;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  gap: 1rem;
}

.app-card-text-underline {
  position: relative;
}

.app-card-text-underline::after {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
  bottom: -0.25rem;
  background-color: var(--grayish-color);
}

.points-amount-label {
  width: 100%;
  text-align: end;
  color: var(--secondary-color-text);
  z-index: 1;
  margin-bottom: 0.25rem;
  font-size: 1.25em;
}

.points-amount-label-error {
  text-align: center;
  color: var(--error-color);
  font-weight: 500;
}

.succes-alert {
  position: fixed;
  /* width: 100%; */
  min-width: 200px;
  /* bottom: 30px; */
  min-height: 35px;
  padding-top: 2px !important;
  padding-bottom: 0px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  z-index: 1 !important;
  /* border-radius: 0px; */
  /* margin-top: 55px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  letter-spacing: 0.3px;
  background-color: var(--main-color);
  border-color: var(--main-color);
  color: white;
  /* top: 90%; left: 50%; */
  bottom: 5%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.succes-alert>span {
  padding: 0.5rem;
}

.error-alert {
  position: absolute;
  min-width: 200px;
  padding-top: 2px !important;
  padding-bottom: 0px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  z-index: 100 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  letter-spacing: 0.3px;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  color: white;
  top: 91%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.error-alert>span {
  padding: 0.5rem;
}

.close {
  text-shadow: none;
  cursor: pointer;
}

@media(max-width: 45rem) {
  .app-card-image-and-data-container {
    flex-direction: column;
  }
}

@media(max-width: 20rem) {
  .app-card-image-container > img {
    max-width: 8rem;
    max-height: 8rem;
  }
}