.texts-container {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.landing-page-text {
  font-size: 1.5em;
  line-height: 1em;
}

.landing-page-text-big {
  font-size: 2.5em;
  line-height: 1em;
}

.text-bold {
  font-weight: 500;
}

.text-title {
  padding: 0;
  margin: 0;
}

.text-orange {
  color: #E06234;
}

.text-green {
  color: #6AAE9F;
}

.text-black{
  color:black;
}

.text-centered {
  text-align: center;
}

.text-link {
  cursor: pointer;
}

.text-link:hover {
  text-decoration: underline;
}