.icon-card-wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
}

.icon-card-border-container {
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem;
  padding-top: 1.5rem;
  border: 1px solid var(--main-color);
  border-radius: var(--standard-border-radius);
  background-color: var(--app-background-color);
  position: relative;
}

.icon-card-border-container-info {
  border-color: var(--info-color);
}

.icon-card-border-container-error {
  border-color: var(--error-color);
}

.icon-card-icon-container {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: -1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--app-background-color);
}

.icon-card-border-container-info > .icon-card-icon-container::after {
  content: '';
  width: 1.5rem;
  height: 1.5rem;
  background-image: url('../assets/info-circle.svg');
  background-size: cover;
}

.icon-card-border-container-error > .icon-card-icon-container::after {
  content: '';
  width: 1.5rem;
  height: 1.5rem;
  background-image: url('../assets/exclamation-triangle.svg');
  background-size: cover;
}