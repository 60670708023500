.login-form-container {
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translateY(-50%) translateX(-50%);
  width: 30rem;
  max-width: 80vw;
  max-height: calc(100vh - 2 * (var(--header-height) + 1rem));
  border: 1px solid var(--secondary-color-lighter);
  border-radius: var(--standard-border-radius);
  overflow-y: auto;
  background-color: var(--app-background-color);
}

/* .login-form-container::-webkit-scrollbar {
  display: none;
} */

.login-form-items-container {
  width: 100%;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 1rem;
  max-height: 100%;
}

.login-form-title-container {
  text-align: center;
  font-weight: 500;
  font-size: 1.2em;
}

.login-form-input-description-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 0.15rem;
}

.login-form-submit-button-container {
  display: flex;
  justify-content: flex-end;
}

.login-dorm-error-info-container {
  width: 100%;
  padding: 0.5rem;
  background-color: var(--error-color-light);
  border: 1px solid var(--error-color);
  border-radius: var(--standard-border-radius);
  font-size: 0.9em;
}